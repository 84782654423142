import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {
  Component,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
  AfterViewInit,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FieldWrapper } from '@ngx-formly/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
  selector: 'formly-suffix-wrapper',
  template: `
    <ng-container #fieldComponent></ng-container>
    <ng-template #matSuffix>
      <div class="flex flex-row">
        <button
          *ngIf="props['matTooltip']"
          mat-icon-button
          color="accent"
          type="button"
          aria-label="Info"
          matTooltip="{{ props['matTooltip'] }}"
          matTooltipClass="{{ props['matTooltipClass'] ?? '' }}"
          #tooltip="matTooltip"
          (click)="tooltip.toggle(); $event.stopPropagation()">
          <mat-icon>info_outline</mat-icon>
        </button>
        <button
          *ngIf="props['dropdown']"
          mat-icon-button
          color="accent"
          type="button"
          [disabled]="this.formControl.disabled">
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <button
          *ngIf="props['clear']"
          type="button"
          color="accent"
          mat-icon-button
          aria-label="Clear"
          [disabled]="
            this.formControl.value === '' ||
            this.formControl.value === null ||
            this.formControl.value === undefined ||
            this.formControl.disabled
          "
          (click)="formControl.setValue(null)">
          <mat-icon>close</mat-icon>
        </button>
        <ng-container *ngIf="field.type === 'datepicker' && existingSuffix">
          <ng-container *ngTemplateOutlet="existingSuffix"></ng-container>
        </ng-container>
      </div>
    </ng-template>
  `,
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    CommonModule,
    MatTooltipModule,
    MatDatepickerModule,
  ],
})
export class MatSuffixWrapperComponent
  extends FieldWrapper
  implements AfterViewInit
{
  existingSuffix: any;

  @ViewChild('fieldComponent', { read: ViewContainerRef })
  override fieldComponent!: ViewContainerRef;
  @ViewChild('matSuffix') matSuffix?: TemplateRef<any>;

  ngAfterViewInit() {
    this.existingSuffix = this.props['suffix'];
    if (this.matSuffix) {
      setTimeout(() => {
        this.props['suffix'] = this.matSuffix;
      });
    }
  }
}
